<template>
  <PromotionTemplate
    :bannerStyle="{ backgroundImage: `url(${rwdBannerBg})`, backgroundPosition: 'right' }"
    :hasBanner="banner"
    :class="bannerRwdClass"
  >
    <template #bannerItem>
      <BannerItemV2 v-bind="banner" :lang="imgLang" @getCurrentBannerRwdClass="getCurrentBannerRwdClass"></BannerItemV2>
    </template>

    <template #content>
      <FundAccount :campaignID="campaignID" />
    </template>
  </PromotionTemplate>
</template>

<script>
import PromotionTemplate from '@/components/promotion/common/PromotionTemplate.vue';
import FundAccount from '@/components/promotion/xmasSpinAndWin/FundAccount.vue';
import BannerItemV2 from '@/components/banner/item/v2/BannerItem.vue';
import { PROMOTION_TYPE } from '@/constants/promotion';

export default {
  name: 'XmasSpinAndWin',
  components: {
    PromotionTemplate,
    FundAccount,
    BannerItemV2
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      bannerPromo: [1002],
      banner: null,
      bannerRwdClass: null
    };
  },
  computed: {
    campaignID() {
      let campaign =
        this.$store.state.promotion.eligibleCampaigns.find(c => c.campaignType === PROMOTION_TYPE.XMAS_SPIN_AND_WIN) ||
        {};
      if (!campaign.id) {
        this.$router.push('/404');
        return null;
      }
      return campaign.id;
    },
    imgLang() {
      return this.$store.state.common.lang;
    },
    rwdBannerBg() {
      if (this.banner?.img) {
        switch (this.bannerRwdClass) {
          case 'long':
            return this.banner?.imgLarge;
          case 'mid-long':
          case 'mid':
            return this.banner?.img;
          case 'mid-short':
          case 'short':
          case 'x-short':
          case 'xx-short':
          case 'xxx-short':
            return this.banner?.imgSmall;
          default:
            return this.banner?.img;
        }
      }

      return null;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getBannerSetting();
    },
    getBannerSetting() {
      const regulatorConfig = this.$config.banner(this.regulator).filter(el => el.promotionId === this.campaignID)[0];

      this.banner = { ...regulatorConfig };
    },

    getCurrentBannerRwdClass(bannerRwdClass) {
      this.bannerRwdClass = bannerRwdClass;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/xmasSpinAndWin.scss';
</style>
