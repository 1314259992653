<template>
  <div class="meta-box">
    <div class="title-container">
      <h3 class="title">{{ $t('promotion.xmasSpinAndWin.title') }}</h3>
    </div>

    <!-- action btn -->
    <el-row :gutter="20" class="sub-box1">
      <el-col :xs="24">
        <p class="desc-title text-center">
          {{ $t('promotion.xmasSpinAndWin.desc') }}
        </p>
      </el-col>
      <el-col :xs="24" class="mt-4">
        <div class="sub-title">
          {{ $t('promotion.xmasSpinAndWin.buttonDesc') }}
        </div>
      </el-col>
      <el-col :xs="24" class="button-box opt-button-box">
        <el-button class="el-button btn-blue long-button" data-testid="opt" @click="goToAppStore">
          {{ $t('promotion.xmasSpinAndWin.button.join') }}
        </el-button>
      </el-col>
    </el-row>

    <!-- description -->
    <el-row :gutter="20" class="sub-box2">
      <el-col :xs="24">
        <!-- how to claim -->
        <Description :title="claimTitle" useSlotForLiTag>
          <li class="unstyled" v-for="(item, index) in claimDescriptions" :key="index">
            {{ item }}
          </li>
        </Description>
      </el-col>
      <el-col :xs="24">
        <!-- eligibility -->
        <Description :title="eligibilityTitle" useSlotForLiTag>
          <li v-for="(item, index) in eligibilityDescriptions" :key="index">
            {{ item }}
          </li>
        </Description>
      </el-col>
      <el-col :xs="24" class="image-box">
        <a href="https://apps.apple.com/au/app/pu-prime-trading-app/id1575499773?mt=8" target="_blank">
          <!-- <img src="@/assets/images/promotion/xmasSpinAndWin/xmasSpinAndWin.png" alt /> -->
          <img :src="getImagePath" alt />
        </a>
      </el-col>
      <el-col :xs="24" class="agreeTnc-box">
        <i18n path="promotion.xmasSpinAndWin.tnc">
          <template v-slot:link>
            <a href="#" data-testid="tnc" @click="showAgreeTnc = true">
              {{ $t('promotion.xmasSpinAndWin.link') }}
            </a>
          </template>
        </i18n>
      </el-col>
    </el-row>
    <GeneralDialog :visible.sync="showAgreeTnc" visibleFooter needFullscreen hideChat @close="showAgreeTnc = false">
      <div class="top">
        <div class="header-text">{{ $t('promotion.xmasSpinAndWin.dialog.tnc') }}</div>
        <ol>
          <li>{{ $t('promotion.xmasSpinAndWin.dialog.tncContext.tnc1') }}</li>
          <li>{{ $t('promotion.xmasSpinAndWin.dialog.tncContext.tnc2') }}</li>
          <li>{{ $t('promotion.xmasSpinAndWin.dialog.tncContext.tnc3') }}</li>
          <li>{{ $t('promotion.xmasSpinAndWin.dialog.tncContext.tnc4') }}</li>
        </ol>
      </div>

      <div class="form-box">
        <div class="btn-box">
          <el-button class="btn-blue" @click="showAgreeTnc = false" data-testid="agreeAction">
            {{ $t('promotion.xmasSpinAndWin.dialog.back') }}
          </el-button>
        </div>
      </div>
    </GeneralDialog>
  </div>
</template>

<script>
import GeneralDialog from '@/components/dialog/general/Dialog.vue';
import Description from './Desciption.vue';

export default {
  name: 'FundAccount',
  components: {
    Description,
    GeneralDialog
  },
  props: {
    campaignID: {
      type: Number,
      default: 1002
    }
  },
  mixins: [],
  data() {
    return {
      showAgreeTnc: false,
      claimTitle: this.$t('promotion.xmasSpinAndWin.claimTitle'),
      claimDescriptions: this.$t('promotion.xmasSpinAndWin.claimDescriptions'),
      eligibilityTitle: this.$t('promotion.xmasSpinAndWin.eligibilityTitle'),
      eligibilityDescriptions: this.$t('promotion.xmasSpinAndWin.eligibleDescriptions')
    };
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    getImagePath() {
      // Determine the screen width and choose the appropriate image path
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        return require('@/assets/images/promotion/xmasSpinAndWin/xmasSpinAndWin-small.png');
      } else {
        return require('@/assets/images/promotion/xmasSpinAndWin/xmasSpinAndWin.png');
      }
    }
  },
  methods: {
    goToAppStore() {
      window.open('https://apps.apple.com/au/app/pu-prime-trading-app/id1575499773?mt=8', '_blank');
    }
  }
};
</script>
<style lang="scss" scoped>
.image-box {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  img {
    width: 60%;
  }
}
/deep/ {
  .btn-box {
    margin-top: 65px;
    text-align: center;
  }
  .el-button {
    min-width: 278px;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    background-color: $future-blue;
    color: white;
    &.is-disabled {
      background-color: $future-blue;
      color: white;
    }
  }
}

@media (max-width: 480px) {
  .image-box {
    img {
      width: 100%;
    }
  }
}

.top {
  .header-text {
    font-weight: 800;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 20px;
  }
  ol {
    li {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 21px;
    }
  }
}
</style>
